<template>
  <div
    :class="{ dark: section.dark_mode }"
    v-if="gameday"
    class="c-hero__wrapper"
  >
    <div
      v-if="template.name === 'hero-1' && $screen.tablet"
      class="component c-hero c-hero--template-1 display-flex position-relative"
      :class="navigation_class"
      :style="styledBg"
    >
      <div
        class="
          row
          width-100
          min-height-100
          flex-shrink-0
          display-flex
          flex-direction-column
          justify-content-center
          align-items-center
        "
      >
        <div
          v-if="
            section.countdown_enabled &&
            gameday &&
            gameday.game &&
            gameday.game.datetime != null &&
            time > 0
          "
          class="
            c-hero__countdown--wrapper
            position-relative
            padding-b-24
            z-index-3
          "
        >
          <div class="c-hero__countdown">
            <vue-countdown
              class="c-hero__countdown__wrapper"
              :time="time"
              v-slot="{ days, hours, minutes, seconds }"
            >
              <div class="c-hero__countdown--time margin-x-48">
                <div>{{ days }}</div>
                <div>Days</div>
              </div>

              <div class="c-hero__countdown--time margin-x-48">
                <div>{{ hours }}</div>
                <div>Hours</div>
              </div>
              <div class="c-hero__countdown--time margin-x-48">
                <div>{{ minutes }}</div>
                <div>Minutes</div>
              </div>
              <div class="c-hero__countdown--time margin-x-48">
                <div>{{ seconds }}</div>
                <div>Seconds</div>
              </div>
            </vue-countdown>
          </div>
        </div>
        <div class="width-100 c-hero__content display-flex">
          <div
            v-if="gameday && gameday.homeTeam"
            class="c-hero__team c-hero__team--home"
          >
            <div class="c-hero__team--image">
              <img
                v-if="gameday && gameday.homeTeam && gameday.homeTeam.logo"
                :src="gameday.homeTeam.logo + '?width=120'"
                :alt="gameday.homeTeam.title"
              />
            </div>
            <div
              v-if="
                gameday &&
                gameday.homeTeam &&
                gameday.homeTeam.links &&
                gameday.homeTeam.links.length > 0
              "
              class="c-hero__team--links"
            >
              <team-links
                :links="gameday.homeTeam.links"
                :team="'home'"
              ></team-links>
            </div>
            <div
              class="
                c-hero__team--title
                font-size-18
                bp-1440:line-height-115 bp-1440:font-size-30
                text-align-center
                font-weight-700
              "
              v-if="gameday && gameday.homeTeam && gameday.homeTeam.title"
            >
              {{ gameday.homeTeam.title }}
            </div>
            <div
              v-if="
                gameday &&
                gameday.homeTeam &&
                gameday.homeTeam.record &&
                gameday.homeTeam.record.overall
              "
              class="c-hero__team--record font-size-16 line-height-150"
            >
              {{ gameday.homeTeam.record.overall }}
              <template
                v-if="
                  gameday &&
                  gameday.homeTeam &&
                  gameday.homeTeam.record &&
                  gameday.homeTeam.record.conference
                "
                >({{ gameday.homeTeam.record.conference }})</template
              >
            </div>
          </div>
          <div class="c-hero__info flex-item-1 text-align-center">
            <h1
              class="
                c-hero__info--title
                bp-1280:font-size-72
                bp-1024:font-size-48
                line-height-125
                font-size-21 font-weight-700
                text-transform-uppercase
                margin-b-12
              "
              v-if="section.headline"
            >
              {{ section.headline }}
            </h1>
            <h2
              class="
                c-hero__info--subtitle
                line-height-125
                font-size-21 font-weight-700
                text-transform-uppercase
              "
              v-if="section.subHeadline"
            >
              {{ section.subHeadline }}
            </h2>

            <div class="c-hero__info--links margin-t-24">
              <ul class="reset-list">
                <li
                  class="padding-t-12"
                  v-if="gameday.game && gameday.game.datetime"
                >
                  {{
                    gameday.game.datetime
                      ? moment(gameday.game.datetime).format("dddd, MMMM DD")
                      : "N/A"
                  }}
                </li>
                <li
                  class="margin-t-12"
                  v-if="gameday.game && gameday.game.time"
                >
                  {{ gameday.game.time }}
                </li>
                <li
                  class="margin-t-12"
                  v-if="
                    gameday.game.coverage && gameday.game.coverage.streaming
                  "
                >
                  <a :href="gameday.game.coverage.streaming">Watch Live</a>
                </li>
                <li
                  class="margin-t-12"
                  v-else-if="gameday.game.coverage && gameday.game.coverage.tv"
                >
                  {{ gameday.game.coverage.tv }}
                </li>
                <li class="margin-t-12" v-if="gameday.game.location">
                  {{ gameday.game.location }}
                </li>
              </ul>
            </div>
            <div class="c-hero__sponsor">
              <div
                v-if="section.sponsor && section.sponsor.image"
                class="s-common__header--ad"
              >
                <a :href="section.sponsor.url" v-if="section.sponsor.url">
                  <img
                    :src="section.sponsor.image"
                    :alt="section.headline + ' Sponsor'"
                  />
                </a>
                <img
                  v-if="!section.sponsor.url"
                  :src="section.sponsor.image"
                  :alt="section.headline + ' Sponsor'"
                />
              </div>
            </div>
          </div>
          <div
            v-if="gameday && gameday.awayTeam"
            class="c-hero__team c-hero__team--away"
          >
            <div class="c-hero__team--image">
              <img
                v-if="gameday && gameday.awayTeam && gameday.awayTeam.logo"
                :src="gameday.awayTeam.logo + '?width=120'"
                :alt="gameday.awayTeam.title"
              />
            </div>
            <div
              v-if="
                gameday &&
                gameday.awayTeam &&
                gameday.awayTeam.links &&
                gameday.awayTeam.links.length > 0
              "
              class="c-hero__team--links"
            >
              <team-links
                :links="gameday.awayTeam.links"
                :team="'away'"
              ></team-links>
            </div>
            <div
              class="
                c-hero__team--title
                font-size-18
                bp-1440:line-height-115 bp-1440:font-size-30
                text-align-center
                font-weight-700
              "
              v-if="gameday && gameday.awayTeam && gameday.awayTeam.title"
            >
              {{ gameday.awayTeam.title }}
            </div>
            <div
              v-if="
                gameday &&
                gameday.awayTeam &&
                gameday.awayTeam.record &&
                gameday.awayTeam.record.overall
              "
              class="c-hero__team--record"
            >
              {{ gameday.awayTeam.record.overall }}
              <template
                v-if="
                  gameday &&
                  gameday.awayTeam &&
                  gameday.awayTeam.record &&
                  gameday.awayTeam.record.conference
                "
                >({{ gameday.awayTeam.record.conference }})</template
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="template.name === 'hero-3' && $screen.tablet"
      class="
        component
        c-hero c-hero--template-3
        display-flex
        flex-direction-column
        align-items-center
        justify-content-center
        position-relative
      "
      :class="navigation_class"
      :style="styledBg"
    >
      <div class="row width-100">
        <div class="width-100 flex-item-1 c-hero__content">
          <div
            class="
              c-hero__teams
              display-flex
              align-items-center
              justify-content-center
            "
          >
            <div
              v-if="gameday && gameday.homeTeam"
              class="
                c-hero__team c-hero__team--home
                display-flex
                align-items-center
                flex-item-1
                justify-content-flex-end
              "
            >
              <div class="c-hero__team--content text-align-right flex-item-1">
                <div
                  class="
                    c-hero__team--title
                    font-size-18
                    bp-1440:line-height-115 bp-1440:font-size-30
                    font-weight-700
                  "
                  v-if="gameday && gameday.homeTeam && gameday.homeTeam.title"
                >
                  {{ gameday.homeTeam.title }}
                </div>

                <div
                  v-if="
                    gameday &&
                    gameday.homeTeam &&
                    gameday.homeTeam.record &&
                    gameday.homeTeam.record.overall
                  "
                  class="c-hero__team--record font-size-16 line-height-150"
                >
                  {{ gameday.homeTeam.record.overall }}
                  <template
                    v-if="
                      gameday &&
                      gameday.homeTeam &&
                      gameday.homeTeam.record &&
                      gameday.homeTeam.record.conference
                    "
                    >({{ gameday.homeTeam.record.conference }})</template
                  >
                </div>
              </div>

              <div
                v-if="
                  gameday &&
                  gameday.homeTeam &&
                  gameday.homeTeam.links &&
                  gameday.homeTeam.links.length > 0
                "
                class="c-hero__team--links"
              >
                <team-links
                  :links="gameday.homeTeam.links"
                  :team="'home'"
                ></team-links>
              </div>
              <div class="c-hero__team--image flex-shrink-0">
                <img
                  v-if="gameday && gameday.homeTeam && gameday.homeTeam.logo"
                  :src="gameday.homeTeam.logo + '?width=120'"
                  :alt="gameday.homeTeam.title"
                />
              </div>
            </div>
            <div class="c-hero__teams--vsat">vs.</div>
            <div
              v-if="gameday && gameday.awayTeam"
              class="
                c-hero__team c-hero__team--away
                display-flex
                align-items-center
                flex-item-1
              "
            >
              <div class="c-hero__team--image flex-shrink-0">
                <img
                  v-if="gameday && gameday.awayTeam && gameday.awayTeam.logo"
                  :src="gameday.awayTeam.logo + '?width=120'"
                  :alt="gameday.awayTeam.title"
                />
              </div>
              <div
                v-if="
                  gameday &&
                  gameday.awayTeam &&
                  gameday.awayTeam.links &&
                  gameday.awayTeam.links.length > 0
                "
                class="c-hero__team--links"
              >
                <team-links
                  :links="gameday.awayTeam.links"
                  :team="'away'"
                ></team-links>
              </div>
              <div class="c-hero__team--content flex-item-1">
                <div
                  class="
                    c-hero__team--title
                    font-size-18
                    bp-1440:line-height-115 bp-1440:font-size-30
                    font-weight-700
                  "
                  v-if="gameday && gameday.awayTeam && gameday.awayTeam.title"
                >
                  {{ gameday.awayTeam.title }}
                </div>
                <div
                  v-if="
                    gameday &&
                    gameday.awayTeam &&
                    gameday.awayTeam.record &&
                    gameday.awayTeam.record.overall
                  "
                  class="c-hero__team--record"
                >
                  {{ gameday.awayTeam.record.overall }}
                  <template
                    v-if="
                      gameday &&
                      gameday.awayTeam &&
                      gameday.awayTeam.record &&
                      gameday.awayTeam.record.conference
                    "
                    >({{ gameday.awayTeam.record.conference }})</template
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              section.countdown_enabled &&
              gameday &&
              gameday.game &&
              gameday.game.datetime != null &&
              time > 0
            "
            class="
              c-hero__countdown--wrapper
              position-relative
              padding-b-24
              z-index-3
            "
          >
            <div class="c-hero__countdown">
              <vue-countdown
                class="c-hero__countdown__wrapper"
                :time="time"
                v-slot="{ days, hours, minutes, seconds }"
              >
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ days }}</div>
                  <div>Days</div>
                </div>

                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ hours }}</div>
                  <div>Hours</div>
                </div>
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ minutes }}</div>
                  <div>Minutes</div>
                </div>
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ seconds }}</div>
                  <div>Seconds</div>
                </div>
              </vue-countdown>
            </div>
          </div>
          <div class="c-hero__info text-align-center">
            <h2
              class="
                c-hero__info--title
                bp-1440:font-size-72
                bp-1280:font-size-48
                line-height-125
                font-size-48 font-weight-700
                text-transform-uppercase
                margin-0 margin-b-24
              "
              v-if="section.headline"
            >
              {{ section.headline }}
            </h2>
            <h3
              class="
                c-hero__info--subtitle
                line-height-125
                font-size-21 font-weight-700
                text-transform-uppercase
              "
              v-if="section.subHeadline"
            >
              {{ section.subHeadline }}
            </h3>
            <div class="c-hero__info--links margin-t-24">
              <ul class="reset-list">
                <li v-if="gameday.game && gameday.game.datetime">
                  {{
                    gameday.game.datetime
                      ? moment(gameday.game.datetime).format("dddd, MMMM DD")
                      : "N/A"
                  }}
                </li>
                <li v-if="gameday.game && gameday.game.time">
                  {{ gameday.game.time }}
                </li>
                <li
                  v-if="
                    gameday.game.coverage && gameday.game.coverage.streaming
                  "
                >
                  <a :href="gameday.game.coverage.streaming">Watch Live</a>
                </li>
                <li
                  v-else-if="gameday.game.coverage && gameday.game.coverage.tv"
                >
                  {{ gameday.game.coverage.tv }}
                </li>
                <li v-if="gameday.game.location">
                  {{ gameday.game.location }}
                </li>
              </ul>
            </div>
            <div class="c-hero__sponsor">
              <div
                v-if="section.sponsor && section.sponsor.image"
                class="s-common__header--ad"
              >
                <a :href="section.sponsor.url" v-if="section.sponsor.url">
                  <img
                    :src="section.sponsor.image"
                    :alt="section.headline + ' Sponsor'"
                  />
                </a>
                <img
                  v-if="!section.sponsor.url"
                  :src="section.sponsor.image"
                  :alt="section.headline + ' Sponsor'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="template.name === 'hero-4' && $screen.tablet"
      class="
        component
        c-hero c-hero--template-4
        display-flex
        flex-direction-column
        align-items-center
        justify-content-center
        position-relative
      "
      :class="navigation_class"
      :style="styledBg"
    >
      <div class="row width-100">
        <div class="width-100 flex-item-1 c-hero__content">
          <div
            v-if="
              this.$root.gameday &&
              this.$root.gameday.sport &&
              this.$root.gameday.sport.title
            "
            class="
              c-hero__sport
              font-size-24
              bp-1440:font-size-30
              font-weight-700
              line-height-125
              text-align-center
            "
          >
            {{ this.$root.gameday.sport.title }}
          </div>
          <div
            class="
              c-hero__teams
              display-flex
              align-items-center
              justify-content-center
            "
          >
            <div
              v-if="gameday && (gameday.homeTeam || gameday.awayTeam)"
              class="c-hero__team c-hero__team--home"
            >
              <div class="c-hero__team--image flex-shrink-0">
                <img
                  v-if="
                    gameday &&
                    gameday.homeTeam &&
                    gameday.homeTeam.logo &&
                    gameday.homeTeam.title !== gameday.game.opponent.title
                  "
                  :src="gameday.homeTeam.logo + '?width=120'"
                  :alt="gameday.homeTeam.title"
                />
                <img
                  v-else-if="
                    gameday &&
                    gameday.awayTeam &&
                    gameday.awayTeam.logo &&
                    gameday.awayTeam.title !== gameday.game.opponent.title
                  "
                  :src="gameday.awayTeam.logo + '?width=120'"
                  :alt="gameday.awayTeam.title"
                />
                <img
                  v-else
                  :src="gameday.homeTeam.logo + '?width=120'"
                  :alt="gameday.homeTeam.title"
                />
              </div>
            </div>
          </div>
          <div
            v-if="
              section.countdown_enabled &&
              gameday &&
              gameday.game &&
              gameday.game.datetime != null &&
              time > 0
            "
            class="
              c-hero__countdown--wrapper
              position-relative
              padding-b-24
              z-index-3
            "
          >
            <div class="c-hero__countdown">
              <vue-countdown
                class="c-hero__countdown__wrapper"
                :time="time"
                v-slot="{ days, hours, minutes, seconds }"
              >
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ days }}</div>
                  <div>Days</div>
                </div>

                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ hours }}</div>
                  <div>Hours</div>
                </div>
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ minutes }}</div>
                  <div>Minutes</div>
                </div>
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ seconds }}</div>
                  <div>Seconds</div>
                </div>
              </vue-countdown>
            </div>
          </div>
          <div class="c-hero__info text-align-center">
            <h2
              class="
                c-hero__info--title
                bp-1440:font-size-72
                line-height-125
                font-size-48 font-weight-700
                text-transform-uppercase
                margin-0 margin-b-24
              "
              v-if="section.headline"
            >
              {{ section.headline }}
            </h2>
            <h3
              class="
                c-hero__info--subtitle
                line-height-125
                font-size-21 font-weight-700
                text-transform-uppercase
              "
              v-if="section.subHeadline"
            >
              {{ section.subHeadline }}
            </h3>
            <div class="c-hero__info--links margin-t-24">
              <ul class="reset-list">
                <li v-if="gameday.game && gameday.game.datetime">
                  {{
                    gameday.game.datetime
                      ? moment(gameday.game.datetime).format("dddd, MMMM DD")
                      : "N/A"
                  }}
                </li>
                <li v-if="gameday.game && gameday.game.time">
                  {{ gameday.game.time }}
                </li>
                <li
                  v-if="
                    gameday.game.coverage && gameday.game.coverage.streaming
                  "
                >
                  <a :href="gameday.game.coverage.streaming">Watch Live</a>
                </li>
                <li
                  v-else-if="gameday.game.coverage && gameday.game.coverage.tv"
                >
                  {{ gameday.game.coverage.tv }}
                </li>
                <li v-if="gameday.game.location">
                  {{ gameday.game.location }}
                </li>
              </ul>
            </div>
            <div class="c-hero__sponsor">
              <div
                v-if="section.sponsor && section.sponsor.image"
                class="s-common__header--ad"
              >
                <a :href="section.sponsor.url" v-if="section.sponsor.url">
                  <img
                    :src="section.sponsor.image"
                    :alt="section.headline + ' Sponsor'"
                  />
                </a>
                <img
                  v-if="!section.sponsor.url"
                  :src="section.sponsor.image"
                  :alt="section.headline + ' Sponsor'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="template.name === 'hero-5' && $screen.tablet"
      class="
        component
        c-hero c-hero--template-5
        display-flex
        flex-direction-column
        align-items-center
        justify-content-center
        position-relative
      "
      :class="navigation_class"
      :style="styledBg"
    >
      <div class="row width-100">
        <div
          class="
            width-100
            flex-item-1
            c-hero__content
            display-flex
            align-items-center
            justify-content-center
            flex-direction-column
          "
        >
          <div
            v-if="
              this.$root.gameday &&
              this.$root.gameday.sport &&
              this.$root.gameday.sport.title
            "
            class="
              c-hero__sport
              font-size-24
              bp-1440:font-size-30
              font-weight-700
              line-height-125
              text-align-center
            "
          >
            {{ this.$root.gameday.sport.title }}
          </div>
          <div
            v-if="gameday && gameday.homeTeam"
            class="c-hero__team c-hero__team--home"
          >
            <div class="c-hero__team--image flex-shrink-0">
              <img
                v-if="gameday && gameday.homeTeam && gameday.homeTeam.logo"
                :src="gameday.homeTeam.logo + '?width=120'"
                :alt="gameday.homeTeam.title"
              />
            </div>
          </div>
          <div
            v-if="
              section.countdown_enabled &&
              gameday &&
              gameday.game &&
              gameday.game.datetime != null &&
              time > 0
            "
            class="
              c-hero__countdown--wrapper
              position-relative
              padding-b-24
              z-index-3
            "
          >
            <div class="c-hero__countdown">
              <vue-countdown
                class="c-hero__countdown__wrapper"
                :time="time"
                v-slot="{ days, hours, minutes, seconds }"
              >
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ days }}</div>
                  <div>Days</div>
                </div>

                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ hours }}</div>
                  <div>Hours</div>
                </div>
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ minutes }}</div>
                  <div>Minutes</div>
                </div>
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ seconds }}</div>
                  <div>Seconds</div>
                </div>
              </vue-countdown>
            </div>
          </div>
          <div class="c-hero__info text-align-center">
            <h2
              class="
                c-hero__info--title
                bp-1440:font-size-72
                line-height-125
                font-size-48 font-weight-700
                text-transform-uppercase
              "
              v-if="section.headline"
            >
              {{ section.headline }}
            </h2>
            <h3
              class="
                c-hero__info--subtitle
                line-height-125
                font-size-21 font-weight-700
                text-transform-uppercase
              "
              v-if="section.subHeadline"
            >
              {{ section.subHeadline }}
            </h3>
            <div
              v-if="gameday && gameday.awayTeam"
              class="
                c-hero__team c-hero__team--away
                display-flex
                align-items-center
                justify-content-center
              "
            >
              <div class="c-hero__team--image flex-shrink-0">
                <img
                  v-if="gameday && gameday.awayTeam && gameday.awayTeam.logo"
                  :src="gameday.awayTeam.logo + '?width=72'"
                  :alt="gameday.awayTeam.title"
                />
              </div>
              <div class="c-hero__team--content text-align-left">
                <div
                  class="
                    c-hero__team--title
                    font-size-24
                    bp-1440:line-height-115 bp-1440:font-size-30
                    font-weight-700
                  "
                  v-if="gameday && gameday.awayTeam && gameday.awayTeam.title"
                >
                  vs.
                  {{ gameday.awayTeam.title }}
                </div>
              </div>
            </div>
            <div class="c-hero__info--links margin-t-24">
              <ul class="reset-list">
                <li v-if="gameday.game && gameday.game.datetime">
                  {{
                    gameday.game.datetime
                      ? moment(gameday.game.datetime).format("dddd, MMMM DD")
                      : "N/A"
                  }}
                </li>
                <li v-if="gameday.game && gameday.game.time">
                  {{ gameday.game.time }}
                </li>
                <li
                  v-if="
                    gameday.game.coverage && gameday.game.coverage.streaming
                  "
                >
                  <a :href="gameday.game.coverage.streaming">Watch Live</a>
                </li>
                <li
                  v-else-if="gameday.game.coverage && gameday.game.coverage.tv"
                >
                  {{ gameday.game.coverage.tv }}
                </li>
                <li v-if="gameday.game.location">
                  {{ gameday.game.location }}
                </li>
              </ul>
            </div>
            <div class="c-hero__sponsor">
              <div
                v-if="section.sponsor && section.sponsor.image"
                class="s-common__header--ad"
              >
                <a :href="section.sponsor.url" v-if="section.sponsor.url">
                  <img
                    :src="section.sponsor.image"
                    :alt="section.headline + ' Sponsor'"
                  />
                </a>
                <img
                  v-if="!section.sponsor.url"
                  :src="section.sponsor.image"
                  :alt="section.headline + ' Sponsor'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="template.name === 'hero-2' && $screen.tablet"
      class="
        component
        c-hero
        display-flex
        flex-direction-column
        align-items-center
        justify-content-center
        c-hero--template-2
        position-relative
      "
      :class="navigation_class"
      :style="styledBg"
    >
      <div class="row width-100">
        <div class="width-100 flex-item-1 c-hero__content">
          <div
            v-if="
              section.countdown_enabled &&
              gameday &&
              gameday.game &&
              gameday.game.datetime != null &&
              time > 0
            "
            class="
              c-hero__countdown--wrapper
              position-relative
              padding-b-24
              z-index-3
            "
          >
            <div class="c-hero__countdown">
              <vue-countdown
                class="c-hero__countdown__wrapper"
                :time="time"
                v-slot="{ days, hours, minutes, seconds }"
              >
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ days }}</div>
                  <div>Days</div>
                </div>

                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ hours }}</div>
                  <div>Hours</div>
                </div>
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ minutes }}</div>
                  <div>Minutes</div>
                </div>
                <div class="c-hero__countdown--time margin-x-48">
                  <div>{{ seconds }}</div>
                  <div>Seconds</div>
                </div>
              </vue-countdown>
            </div>
          </div>
          <div class="c-hero__top text-align-center">
            <h2
              class="
                c-hero__info--title
                bp-1440:font-size-72
                line-height-125
                font-size-48 font-weight-700
                text-transform-uppercase
                margin-b-24
              "
              v-if="section.headline"
            >
              {{ section.headline }}
            </h2>
            <h3
              class="
                c-hero__info--subtitle
                line-height-125
                font-size-21 font-weight-700
                text-transform-uppercase
              "
              v-if="section.subHeadline"
            >
              {{ section.subHeadline }}
            </h3>
          </div>
          <div
            class="
              c-hero__teams
              display-flex
              align-items-center
              justify-content-center
            "
          >
            <div
              v-if="gameday && gameday.homeTeam"
              class="
                c-hero__team c-hero__team--home
                display-flex
                align-items-center
                flex-item-1
                justify-content-flex-end
              "
            >
              <div class="c-hero__team--content text-align-right flex-item-1">
                <div
                  class="
                    c-hero__team--title
                    font-size-18
                    bp-1440:line-height-115 bp-1440:font-size-30
                    font-weight-700
                  "
                  v-if="gameday && gameday.homeTeam && gameday.homeTeam.title"
                >
                  {{ gameday.homeTeam.title }}
                </div>

                <div
                  v-if="
                    gameday &&
                    gameday.homeTeam &&
                    gameday.homeTeam.record &&
                    gameday.homeTeam.record.overall
                  "
                  class="c-hero__team--record font-size-16 line-height-150"
                >
                  {{ gameday.homeTeam.record.overall }}
                  <template
                    v-if="
                      gameday &&
                      gameday.homeTeam &&
                      gameday.homeTeam.record &&
                      gameday.homeTeam.record.conference
                    "
                    >({{ gameday.homeTeam.record.conference }})</template
                  >
                </div>
              </div>

              <div
                v-if="
                  gameday &&
                  gameday.homeTeam &&
                  gameday.homeTeam.links &&
                  gameday.homeTeam.links.length > 0
                "
                class="c-hero__team--links"
              >
                <team-links
                  :links="gameday.homeTeam.links"
                  :team="'home'"
                ></team-links>
              </div>
              <div class="c-hero__team--image flex-shrink-0">
                <img
                  v-if="gameday && gameday.homeTeam && gameday.homeTeam.logo"
                  :src="gameday.homeTeam.logo + '?width=72'"
                  :alt="gameday.homeTeam.title"
                />
              </div>
            </div>
            <div class="c-hero__teams--vsat">vs.</div>
            <div
              v-if="gameday && gameday.awayTeam"
              class="
                c-hero__team c-hero__team--away
                display-flex
                align-items-center
                flex-item-1
              "
            >
              <div class="c-hero__team--image flex-shrink-0">
                <img
                  v-if="gameday && gameday.awayTeam && gameday.awayTeam.logo"
                  :src="gameday.awayTeam.logo + '?width=72'"
                  :alt="gameday.awayTeam.title"
                />
              </div>
              <div
                v-if="
                  gameday &&
                  gameday.awayTeam &&
                  gameday.awayTeam.links &&
                  gameday.awayTeam.links.length > 0
                "
                class="c-hero__team--links"
              >
                <team-links
                  :links="gameday.awayTeam.links"
                  :team="'away'"
                ></team-links>
              </div>
              <div class="c-hero__team--content flex-item-1">
                <div
                  class="
                    c-hero__team--title
                    font-size-18
                    bp-1440:line-height-115 bp-1440:font-size-30
                    font-weight-700
                  "
                  v-if="gameday && gameday.awayTeam && gameday.awayTeam.title"
                >
                  {{ gameday.awayTeam.title }}
                </div>
                <div
                  v-if="
                    gameday &&
                    gameday.awayTeam &&
                    gameday.awayTeam.record &&
                    gameday.awayTeam.record.overall
                  "
                  class="c-hero__team--record"
                >
                  {{ gameday.awayTeam.record.overall }}
                  <template
                    v-if="
                      gameday &&
                      gameday.awayTeam &&
                      gameday.awayTeam.record &&
                      gameday.awayTeam.record.conference
                    "
                    >({{ gameday.awayTeam.record.conference }})</template
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="c-hero__info text-align-center">
            <div class="c-hero__info--links margin-t-24">
              <ul class="reset-list">
                <li v-if="gameday.game && gameday.game.datetime">
                  {{
                    gameday.game.datetime
                      ? moment(gameday.game.datetime).format("dddd, MMMM DD")
                      : "N/A"
                  }}
                </li>
                <li v-if="gameday.game && gameday.game.time">
                  {{ gameday.game.time }}
                </li>
                <li
                  v-if="
                    gameday.game.coverage && gameday.game.coverage.streaming
                  "
                >
                  <a :href="gameday.game.coverage.streaming">Watch Live</a>
                </li>
                <li
                  v-else-if="gameday.game.coverage && gameday.game.coverage.tv"
                >
                  {{ gameday.game.coverage.tv }}
                </li>
                <li v-if="gameday.game.location">
                  {{ gameday.game.location }}
                </li>
              </ul>
            </div>
            <div class="c-hero__sponsor">
              <div
                v-if="section.sponsor && section.sponsor.image"
                class="s-common__header--ad"
              >
                <a :href="section.sponsor.url" v-if="section.sponsor.url">
                  <img
                    :src="section.sponsor.image"
                    :alt="section.headline + ' Sponsor'"
                  />
                </a>
                <img
                  v-if="!section.sponsor.url"
                  :src="section.sponsor.image"
                  :alt="section.headline + ' Sponsor'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!$screen.tablet"
      class="
        component
        c-hero
        display-flex
        flex-direction-column
        align-items-center
        justify-content-center
        c-hero--template-mobile
        position-relative
      "
      :class="navigation_class"
      :style="styledBg"
    >
      <div class="row width-100">
        <div class="width-100 flex-item-1 c-hero__content">
          <div
            v-if="
              section.countdown_enabled &&
              gameday &&
              gameday.game &&
              gameday.game.datetime != null &&
              time > 0
            "
            class="c-hero__countdown"
          >
            <vue-countdown
              class="c-hero__countdown__wrapper padding-b-24"
              :time="time"
              v-slot="{ days, hours, minutes, seconds }"
            >
              <div
                class="c-hero__countdown--time margin-x-12 bp-768:margin-x-24"
              >
                <div>{{ days }}</div>
                <div>Days</div>
              </div>

              <div
                class="c-hero__countdown--time margin-x-12 bp-768:margin-x-24"
              >
                <div>{{ hours }}</div>
                <div>Hours</div>
              </div>
              <div
                class="c-hero__countdown--time margin-x-12 bp-768:margin-x-24"
              >
                <div>{{ minutes }}</div>
                <div>Minutes</div>
              </div>
              <div
                class="c-hero__countdown--time margin-x-12 bp-768:margin-x-24"
              >
                <div>{{ seconds }}</div>
                <div>Seconds</div>
              </div>
            </vue-countdown>
          </div>
          <div class="c-hero__top text-align-center">
            <h2
              class="
                c-hero__info--title
                bp-1440:font-size-72
                line-height-125
                font-size-30
                bp-768:font-size-48
                font-weight-700
                text-transform-uppercase
                margin-b-24
              "
              v-if="section.headline"
            >
              {{ section.headline }}
            </h2>
          </div>
          <div class="c-hero__teams padding-x-24">
            <div
              v-if="gameday && gameday.homeTeam"
              class="
                c-hero__team c-hero__team--home
                display-flex
                align-items-center
                flex-item-1
              "
            >
              <div class="c-hero__team--image flex-shrink-0">
                <img
                  v-if="gameday && gameday.homeTeam && gameday.homeTeam.logo"
                  :src="gameday.homeTeam.logo + '?width=60'"
                  :alt="gameday.homeTeam.title"
                />
              </div>

              <div
                v-if="
                  gameday &&
                  gameday.homeTeam &&
                  gameday.homeTeam.links &&
                  gameday.homeTeam.links.length > 0
                "
                class="c-hero__team--links"
              >
                <team-links
                  :links="gameday.homeTeam.links"
                  :team="'home'"
                ></team-links>
              </div>
              <div class="c-hero__team--content flex-item-1">
                <div
                  class="
                    c-hero__team--title
                    font-size-18
                    bp-1440:line-height-115 bp-1440:font-size-30
                    font-weight-700
                  "
                  v-if="gameday && gameday.homeTeam && gameday.homeTeam.title"
                >
                  {{ gameday.homeTeam.title }}
                </div>

                <div
                  v-if="
                    gameday &&
                    gameday.homeTeam &&
                    gameday.homeTeam.record &&
                    gameday.homeTeam.record.overall
                  "
                  class="c-hero__team--record font-size-16 line-height-150"
                >
                  {{ gameday.homeTeam.record.overall }}
                  <template
                    v-if="
                      gameday &&
                      gameday.homeTeam &&
                      gameday.homeTeam.record &&
                      gameday.homeTeam.record.conference
                    "
                    >({{ gameday.homeTeam.record.conference }})</template
                  >
                </div>
              </div>
            </div>
            <div
              class="
                c-hero__teams--vsat
                text-align-center
                bp-768:text-align-left
                padding-y-24
              "
            >
              vs.
            </div>
            <div
              v-if="gameday && gameday.awayTeam"
              class="
                c-hero__team c-hero__team--away
                display-flex
                align-items-center
                flex-item-1
              "
            >
              <div class="c-hero__team--image flex-shrink-0">
                <img
                  v-if="gameday && gameday.awayTeam && gameday.awayTeam.logo"
                  :src="gameday.awayTeam.logo + '?width=60'"
                  :alt="gameday.awayTeam.title"
                />
              </div>
              <div
                v-if="
                  gameday &&
                  gameday.awayTeam &&
                  gameday.awayTeam.links &&
                  gameday.awayTeam.links.length > 0
                "
                class="c-hero__team--links"
              >
                <team-links
                  :links="gameday.awayTeam.links"
                  :team="'away'"
                ></team-links>
              </div>
              <div class="c-hero__team--content flex-item-1">
                <div
                  class="
                    c-hero__team--title
                    font-size-18
                    bp-1440:line-height-115 bp-1440:font-size-30
                    font-weight-700
                  "
                  v-if="gameday && gameday.awayTeam && gameday.awayTeam.title"
                >
                  {{ gameday.awayTeam.title }}
                </div>
                <div
                  v-if="
                    gameday &&
                    gameday.awayTeam &&
                    gameday.awayTeam.record &&
                    gameday.awayTeam.record.overall
                  "
                  class="c-hero__team--record"
                >
                  {{ gameday.awayTeam.record.overall }}
                  <template
                    v-if="
                      gameday &&
                      gameday.awayTeam &&
                      gameday.awayTeam.record &&
                      gameday.awayTeam.record.conference
                    "
                    >({{ gameday.awayTeam.record.conference }})</template
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="c-hero__info text-align-center">
            <div class="c-hero__info--links margin-t-24">
              <ul class="reset-list">
                <li
                  class="padding-t-12"
                  v-if="gameday.game && gameday.game.datetime"
                >
                  {{
                    gameday.game.datetime
                      ? moment(gameday.game.datetime).format("dddd, MMMM DD")
                      : "N/A"
                  }}
                </li>
                <li
                  class="padding-t-12"
                  v-if="gameday.game && gameday.game.time"
                >
                  {{ gameday.game.time }}
                </li>
                <li
                  class="padding-t-12"
                  v-if="
                    gameday.game.coverage && gameday.game.coverage.streaming
                  "
                >
                  <a :href="gameday.game.coverage.streaming">Watch Live</a>
                </li>
                <li
                  class="padding-t-12"
                  v-else-if="gameday.game.coverage && gameday.game.coverage.tv"
                >
                  {{ gameday.game.coverage.tv }}
                </li>
                <li class="padding-t-12" v-if="gameday.game.location">
                  {{ gameday.game.location }}
                </li>
              </ul>
            </div>
            <div class="c-hero__sponsor">
              <div
                v-if="section.sponsor && section.sponsor.image"
                class="s-common__header--ad"
              >
                <a :href="section.sponsor.url" v-if="section.sponsor.url">
                  <img
                    :src="section.sponsor.image"
                    :alt="section.headline + ' Sponsor'"
                  />
                </a>
                <img
                  v-if="!section.sponsor.url"
                  :src="section.sponsor.image"
                  :alt="section.headline + ' Sponsor'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import SectionBase from "./SectionBase";
import TeamLinks from "./TeamLinks";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  extends: SectionBase,
  name: "SectionHero",
  components: {
    TeamLinks,
    VueCountdown,
  },
  data: () => ({
    swiperOptions: {
      effect: "fade",
      autoplay: {
        delay: 10000,
        disableOnInteraction: false,
      },
    },
    ready_to_play: false,
  }),
  methods: {
    openTeam() {},
  },
  computed: {
    styledBg: function () {
      if (this.section.backgroundImage) {
        return {
          "background-image": "url(" + this.section.backgroundImage + ") ",
        };
      } else if (this.section.useCustomBackgroundColor) {
        return { backgroundColor: this.section.custom_background_color };
      }
      return {};
    },
    time: function () {
      return moment(this.gameday.game.datetime) - moment();
    },
    gameday() {
      if (this.$root.gameday) {
        return this.$root.gameday;
      } else return null;
    },
    navigation_class() {
      if (this.$root.navigation) {
        let _template = this.$root.navigation.templates.find((template) => {
          return template.selected;
        });

        if (_template.name === "nav-1") {
          return "navigation-top";
        } else if (_template.name === "nav-2") {
          return "navigation-left";
        } else {
          return "navigation-mini";
        }
      }
      return "";
    },
    roster_players() {
      let _roster = this.$root.roster.slice();
      return _roster.reverse();
    },
  },
  mounted() {
    setTimeout(() => {
      this.ready_to_play = true;
    }, 100);
  },
};
</script>

<style scoped lang="scss">
.c-hero {
  color: $main-font-color;
  background-size: cover;
  background-position: top center;
  min-height: calc(100vh);
  &__countdown {
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    &__wrapper {
      display: flex;
    }
    &--time {
      &:last-child {
        margin-right: 0;
      }
      text-align: center;
      > div:first-child {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        color: $main-font-color;
        border: 2px dashed $main-font-color;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 42px;
        line-height: 125%;
      }
      div:last-child {
        margin-top: 8px;
        font-size: 1rem;
        line-height: 150%;
        font-weight: 700;
        text-align: center;
      }
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 24px;
      &--time {
        > div:first-child {
          width: 72px;
          height: 72px;
          font-size: 24px;
        }
        div:last-child {
          font-size: 14px;
        }
      }
    }
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $main-color;
    opacity: 0.8;
    z-index: 2;
  }
  &__content {
    z-index: 10;
  }
  &.navigation-top {
    min-height: calc(100vh - 72px);
  }
  &.navigation-left {
    min-height: calc(100vh);
    @media screen and (max-width: 1023px) {
      min-height: calc(100vh - 72px);
    }
  }
  &__wrapper {
    height: 100%;
    width: 100%;
  }
  &__sponsor {
    max-width: 150px;
    margin: 24px auto 0 auto;
  }
  &__info {
    &--links {
      width: 100%;
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      li {
        a {
          color: $main-font-color;
          font-weight: 700;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        &:first-child {
          &:before {
            content: none;
          }
        }
        &:before {
          margin: 0 24px;
          content: "|";
        }
      }
    }
  }
}
.c-hero--template-1 {
  padding: 36px;
  .c-hero {
    &__info {
      padding: 0 48px;
      text-align: center;
      &--title {
        margin-top: -20px;
      }
    }
    &__content {
      padding-top: 103px;
      position: relative;
      &:before {
        content: "";
        display: block;
        height: 1px;
        width: calc(100% - 200px);
        border-bottom: 1px dashed $main-font-color;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(calc(-50%));
      }
    }
    &__team {
      position: relative;
      width: 200px;
      text-align: center;
      &--record {
        margin: 24px 0 0 0;
      }
      &--record {
        color: $color-safe-text;
      }
      &--image {
        &:before {
          content: "";
          display: block;
          height: 103px;
          width: 2px;
          border-left: 1px dashed $main-font-color;
          position: absolute;
          top: -103px;
          left: 50%;
          transform: translateX(calc(-50%));
        }
        position: relative;
        width: 200px;
        height: 200px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 120px;
        }
      }
    }
  }
}
.c-hero--template-2 {
  padding: 24px;
  .c-hero {
    &__sponsor {
      margin: 48px auto 0 auto;
    }
    &__info {
      padding-top: 24px;
      &--title {
        margin: 0;
      }
    }
    &__teams {
      padding: 24px 0;
      &--vsat {
        padding: 0 48px;
        flex-shrink: 0;
        font-size: 18px;
        line-height: 125%;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    &__team {
      &--record {
        margin: 24px 0 0 0;
      }

      &--links {
        margin: 0 24px;
      }
      &--image {
        position: relative;
        width: 128px;
        height: 128px;
        flex-shrink: 0;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 72px;
        }
      }
    }
  }
}
.c-hero--template-3 {
  padding: 24px;
  .c-hero {
    &__sponsor {
      margin: 48px auto 0 auto;
    }
    &__info {
      &--links {
        padding-top: 24px;
        display: block;
      }
      &--title {
        margin: 0;
      }
    }
    &__teams {
      padding: 24px 0 48px 0;
      &--vsat {
        padding: 0 48px;
        flex-shrink: 0;
        font-size: 18px;
        line-height: 125%;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    &__team {
      &--record {
        margin: 24px 0 0 0;
      }

      &--links {
        margin: 0 24px;
      }
      &--image {
        position: relative;
        width: 200px;
        height: 200px;
        flex-shrink: 0;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 120px;
        }
      }
    }
  }
}
.c-hero--template-4 {
  padding: 24px;
  .c-hero {
    &__sponsor {
      margin: 48px auto 0 auto;
    }

    &__info {
      &--links {
        display: block;
      }
      &--subtitle {
        padding-bottom: 24px;
      }
      &--title {
        margin: 0;
      }
    }
    &__teams {
      padding: 24px 0 48px 0;
      &--vsat {
        padding: 0 48px;
        flex-shrink: 0;
        font-size: 18px;
        line-height: 125%;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    &__team {
      &--record {
        margin: 24px 0 0 0;
      }

      &--links {
        margin: 0 24px;
      }
      &--image {
        position: relative;
        width: 200px;
        height: 200px;
        flex-shrink: 0;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 120px;
        }
      }
    }
  }
}
.c-hero--template-5 {
  padding: 24px;
  .c-hero {
    &__sponsor {
      margin: 48px auto 0 auto;
    }
    &__sport {
      margin-bottom: 24px;
    }
    &__info {
      &--links {
        display: block;
      }
      &--subtitle {
      }
      &--title {
        margin: 0 0 36px 0;
      }
    }
    &__team {
      margin-bottom: 36px;
    }
    &__teams {
      padding: 24px 0 48px 0;
      &--vsat {
        padding: 0 48px;
        flex-shrink: 0;
        font-size: 18px;
        line-height: 125%;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    &__team {
      &--away {
        .c-hero__team--image {
          width: 128px;
          height: 128px;
          img {
            max-width: 72px;
          }
        }
      }
      &--record {
        margin: 24px 0 0 0;
      }
      &--content {
        padding: 0 24px;
      }
      &--links {
        margin: 0 24px;
      }
      &--image {
        position: relative;
        width: 200px;
        height: 200px;
        flex-shrink: 0;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 120px;
        }
      }
    }
  }
}
.c-hero--template-mobile {
  padding: 24px;
  .c-hero {
    &__sponsor {
      margin: 48px auto 0 auto;
    }
    &__info {
      &--title {
        margin: 0;
      }
    }
    &__teams {
      max-width: 600px;
      margin: 0 auto;
      padding: 24px 0;
      &--vsat {
        padding: 0 48px;
        flex-shrink: 0;
        font-size: 18px;
        line-height: 125%;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    &__team {
      &--record {
        margin: 12px 0 0 0;
      }

      &--links {
        margin: 0 12px;
      }
      &--image {
        position: relative;
        width: 72px;
        height: 72px;
        flex-shrink: 0;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 48px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
[data-theme="dark"] {
}
</style>
