import { render, staticRenderFns } from "./SectionHero.vue?vue&type=template&id=1489a9ca&scoped=true&"
import script from "./SectionHero.vue?vue&type=script&lang=js&"
export * from "./SectionHero.vue?vue&type=script&lang=js&"
import style0 from "./SectionHero.vue?vue&type=style&index=0&id=1489a9ca&scoped=true&lang=scss&"
import style1 from "./SectionHero.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1489a9ca",
  null
  
)

export default component.exports